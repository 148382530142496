import React from 'react'
import Helmet from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Layout from '../layouts'
import Feature from '../components/Feature'

const meta = {
  title: 'Contact Maxpower Limited',
  url: 'https://www.maxpowerlimited.com/contact/',
}

const apiUrl =
  'https://typebase.app/api/forms/form/85a7de34-4fcf-4716-bbff-48dcc853aa04'

const returnToUrl = 'https://www.maxpowerlimited.uk/thanks/'

const ContactPage = props => {
  const onSubmit = e => {
    e.preventDefault()

    const form = new FormData(e.target)

    if (form.get('_gotcha') !== '') {
      return window.location.replace(returnToUrl)
    }

    let data = {}
    const excludes = ['_gotcha']
    for (const pair of form.entries()) {
      if (!excludes.includes(pair[0])) {
        data[pair[0]] = pair[1]
      }
    }

    return fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify({ data }),
    })
      .then(response => response.json())
      .then(result => {
        if (result.status === 'OK') {
          return window.location.replace(returnToUrl)
        }
      })
  }

  return (
    <Layout {...props}>
      <Helmet
        title={meta.title}
        meta={[
          { itemprop: 'name', content: meta.title },
          { property: 'og:title', content: meta.title },
          { property: 'og:url', content: meta.url },
          { name: 'twitter:title', content: meta.title },
          { name: 'twitter:url', content: meta.url },
        ]}
        link={[{ rel: 'canonical', href: meta.url }]}
      />

      <Feature title={meta.title} />

      <div className="page">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-lg-8">
              <p>
                Please contact us with your enquiry and one of our friendly and
                helpful team will be available to help.
              </p>

              <form onSubmit={onSubmit} method="POST">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name" className="control-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="email" className="control-label">
                        Email address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="company" className="control-label">
                        Company name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="company"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="phone" className="control-label">
                        Phone
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="subject" className="control-label">
                        Subject
                      </label>
                      <select className="form-control" name="subject">
                        <option value="General Enquiry">General Enquiry</option>
                        <option value="Fixed Wire Testing (EICR)">
                          Fixed Wire Testing (EICR)
                        </option>
                        <option value="Emergency Lighting Testing & Inspection">
                          Emergency Lighting Testing & Inspection
                        </option>
                        <option value="Portable Appliance Testing (PAT)">
                          Portable Appliance Testing (PAT)
                        </option>
                        <option value="Load Monitoring">Load Monitoring</option>
                        <option value="Thermal Imaging Surveys">
                          Thermal Imaging Surveys
                        </option>
                        <option value="Remedial works & Repairs">
                          Remedial works & Repairs
                        </option>
                        <option value="Fire Alarm Testing & Inspection">
                          Fire Alarm Testing & Inspection{' '}
                        </option>
                        <option value="LED Lighting Conversions">
                          LED Lighting Conversions
                        </option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="enquiry" className="control-label">
                        Your enquiry
                      </label>
                      <textarea
                        className="form-control text-area"
                        name="enquiry"
                      />
                      <input
                        type="text"
                        name="_gotcha"
                        style={{ display: 'none' }}
                      />
                    </div>

                    <button type="submit" className="btn btn-secondary">
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="d-none d-lg-block col-lg-3 offset-lg-1">
              <h3>Contact details</h3>

              <br />

              <h3>London Office</h3>

              <p>
                71-75 Shelton Street,
                <br />
                Covent Garden,
                <br />
                London,
                <br />
                WC2H 9JQ
              </p>
              <p>
                <strong>Phone:</strong> 020 3633 0956
              </p>
              <strong>Email:</strong>
              <br />
              <span className="email-link">
                <a
                  href="mailto:info@maxpowerlimited.uk"
                  title="Email Maxpower Limited"
                >
                  info@maxpowerlimited.uk
                </a>
              </span>
              <br />

              <br />
              <br />

              <h3>Thetford Office</h3>
              <p>
                Keystone Innovation Centre, <br />
                Croxton road, <br />
                Thetford, <br />
                Norfolk, <br />
                IP24 1JD
              </p>
              <p>
                <strong>Phone:</strong> 01842 558 212
              </p>
              <p>
                <strong>Email:</strong>
                <br />
                <span className="email-link">
                  <a
                    href="mailto:info@maxpowerlimited.uk"
                    title="Email Maxpower Limited"
                  >
                    info@maxpowerlimited.uk
                  </a>
                </span>
              </p>

              <p>
                <strong>Opening hours:</strong>
                <br />
                Monday - Friday - 8am to 5:30pm
              </p>

              <p className="social-icons">
                <a
                  href="https://www.facebook.com/Maxpower-Limited-567584623396346/"
                  title="Like Maxpower Electrical Compliance on Facebook"
                  className="facebook"
                >
                  <FontAwesomeIcon icon={['fab', 'facebook-square']} />
                </a>
                <a
                  href="https://www.linkedin.com/in/barrie-mcguire-01b10a119/"
                  title="Follow Maxpower Electrical Compliance on Linkedin"
                  className="linkedin"
                >
                  <FontAwesomeIcon icon={['fab', 'linkedin']} />
                </a>
                <a
                  href="https://twitter.com/maxpowerlimited"
                  title="Follow Maxpower Electrical Compliance on Twitter"
                  className="twitter"
                >
                  <FontAwesomeIcon icon={['fab', 'twitter-square']} />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
